<template>
    <div class="container pipeform-layout-stepper">
        <div class="row pipeform-layout-stepper-top">
            <div class="divider-line" :style="{ width: `${(100/(steps.length) * (steps.length - 1)) - 10}%` }"></div>
            <div class="steps-wrapper">
                <template v-for="(step, index) in steps">
                    <div :class="['step', isStepActive(index, step)]" :key="index" :style="{ width: `${100 / steps.length}%` }">
                        <div class="circle">
                            <i :class="(step.completed) ? 'glyphicon glyphicon-ok pipeform-icon' : step.icon">
                            </i>
                        </div>
                        <div class="step-title">
                            <p>{{ step.title }}</p>
                            <p class="step-subtitle">{{ step.subtitle }}</p>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="row pipeform-layout-stepper-content">
            <transition :enter-active-class="enterAnimation" :leave-active-class="leaveAnimation" mode="out-in">
                <!--If keep alive-->
                <keep-alive v-if="keepAliveData">
                    <component :is="steps[currentStep.index].component" :clickedNext="nextButton[currentStep.name]" @can-continue="proceed" @change-next="changeNextBtnValue" :current-step="currentStep"></component>
                </keep-alive>
                <!--If not show component and destroy it in each step change-->
                <component v-else :is="steps[currentStep.index].component" :clickedNext="nextButton[currentStep.name]" @can-continue="proceed" @change-next="changeNextBtnValue" :current-step="currentStep"></component>
            </transition>
        </div>
        <div :class="['bottom', (currentStep.index > 0) ? '' : 'only-next']">
            <div v-if="currentStep.index > 0" class="btn btn-default btn-lg stepper-button previous" @click="backStep()">
                <i class="glyphicon glyphicon-menu-left"></i>
                <span>Voltar</span>
            </div>
            <div v-if="!finalStep" :class="['btn btn-default btn-lg stepper-button next', !canContinue ? 'deactivated' : '']" @click="nextStep()">
                <span>Próximo</span>
                <i class="glyphicon glyphicon-menu-right"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            topButtons: {
                type: Boolean,
                default: false
            },
            steps: {
                type: Array,
                default: function() {
                    return [
                        {
                            icon: "glyphicon glyphicon-chevron-right pipeform-icon",
                            name: "MountForm",
                            title: "Campos do Formulário",
                            subtitle: "Subtitle sample"
                        },
                        {
                            icon: "glyphicon glyphicon-chevron-right pipeform-icon",
                            name: "ChoosePipeline",
                            title: "Sample title 2",
                            subtitle: "Subtitle sample"
                        },
                        {
                            icon: "glyphicon glyphicon-chevron-right pipeform-icon",
                            name: "CreateScript",
                            title: "Sample title 2",
                            subtitle: "Subtitle sample"
                        }
                    ];
                }
            }
        },
        order: 1,
        data() {
            return {
                currentStep: {},
                previousStep: {},
                nextButton: {},
                canContinue: false,
                finalStep: false,
                keepAliveData: this.keepAlive
            }
        },
        computed: {
            enterAnimation() {
                if (this.currentStep.index < this.previousStep.index) {
                    return "animated quick fadeInLeft"
                } else {
                    return "animated quick fadeInRight"
                }
            },
            leaveAnimation() {
                if (this.currentStep.index > this.previousStep.index) {
                    return "animated quick fadeOutLeft"
                } else {
                    return "animated quick fadeOutRight"
                }
            }
        },
        methods: {
            isStepActive(index, step) {
                console.log(step)
                if (this.currentStep.index === index) {
                    return "activated"
                } else {
                    return "deactivated"
                }
            },
            activateStep(index, back = false) {
                if (this.steps[index]) {
                    this.previousStep = this.currentStep
                    
                    this.currentStep = {
                        name: this.steps[index].name,
                        index: index
                    }

                    if (index + 1 === this.steps.length) {
                        this.finalStep = true
                    } else {
                        this.finalStep = false
                    }

                    if (!back) {
                        this.$emit("completed-step", this.previousStep)
                    }
                }

                this.$emit("active-step", this.currentStep)
            },
            nextStepAction() {
                this.nextButton[this.currentStep.name] = true;

                if (this.canContinue) {
                    if (this.finalStep) {
                        this.$emit("stepper-finished", this.currentStep);
                    }
                    let currentIndex = this.currentStep.index + 1;
                    this.activateStep(currentIndex);
                }
                this.canContinue = false;
                this.$forceUpdate();
            },
            nextStep () {
                if (!this.$listeners || !this.$listeners['before-next-step']) {
                    this.nextStepAction()
                }

                this.canContinue = false;
                this.$emit("before-next-step", { currentStep: this.currentStep }, (next = true) => {
                    this.canContinue = true;
                    
                    if (next) {
                        this.nextStepAction()
                    }
                });
            },
            backStep() {
                this.$emit("clicking-back");
                let currentIndex = this.currentStep.index - 1;
                if (currentIndex >= 0) {
                    this.activateStep(currentIndex, true);
                }
            },
            proceed(payload) {
                this.canContinue = payload.value;
            },
            changeNextBtnValue(payload) {
                this.nextButton[this.currentStep.name] = payload.nextBtnValue;
                this.$forceUpdate();
            },
            init() {
                // Initiate stepper
                this.activateStep(0);
                this.steps.forEach(step => {
                    this.nextButton[step.name] = false;
                });
            }
        },
        watch: {
            reset (val) {
                if (!val) {
                    return;
                }

                this.keepAliveData = false;
                this.init();
                this.previousStep = {};
                
                this.$nextTick(() => {
                    this.keepAliveData = this.keepAlive;
                    this.$emit('reset', true);
                });
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style>
    .pipeform-layout-stepper {
        background-color: rgba(255, 255, 255, .8);
        /* offset-x | offset-y | blur-radius | spread-radius | color */
        box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 3px rgba(0, 0, 0, .24);
        min-height: 200px;
        border-radius: 5px;
        margin-bottom: 25px;
    }

    .pipeform-layout-stepper-top {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
    }

    .divider-line {
        border-bottom: 1px solid #ccc;
        height: 2px;
        position: absolute;
    }

    .pipeform-icon {
        font-weight: 400;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        top: 0;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-smoothing: antialiased;
    }

    .steps-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 95%;
        left: 0;
        padding: 2% 4%;
    }

    .steps-wrapper .step {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 1.5em;
    }

    .steps-wrapper .step .circle {
        margin-bottom: 2rem;
        padding: 0 1rem;
    }

    .steps-wrapper .step .circle i {
        color: #FFFFFF;
        border-radius: 100rem;
        padding: 2rem;
    }

    .steps-wrapper .step.activated .circle i {
        background-color: #1AB394;
    }

    .steps-wrapper .step.deactivated .circle i {
        background-color: #AAAAAA;
    }

    .steps-wrapper .step .step-title {
        font-size: 14px;
        text-align: center;
        position: absolute;
        top: 100%;
        width: 100%;
        font-weight: bold;
    }

    .steps-wrapper .step .step-subtitle {
        font-size: 12px;
        font-weight: normal;
    }

    .pipeform-layout-stepper-content {
        overflow: hidden;
        margin: 1.5rem 0;
    }

    .steps-wrapper .stepper-button-top {
        z-index: 20;
        padding: .8rem;
        border-radius: 100rem;
        cursor: pointer;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .steps-wrapper .stepper-button-top.previous {
        color: #333;
        left: 1%;
    }

    .steps-wrapper .stepper-button-top.next {
        border: 2px solid #3383C8;
        color: #3383C8;
        right: 1%;
    }

    .steps-wrapper .stepper-button-top.next.deactivated {
        border: 2px solid #CCCCCC !important;
        color: #CCCCCC;
        cursor: not-allowed !important;
    }

    .pipeform-layout-stepper .bottom {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        border-top: 1px solid #ccc;
    }

    /* .pipeform-layout-stepper .bottom .stepper-button {
        padding: .5rem 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    } */

    .pipeform-layout-stepper .bottom.only-next {
        justify-content: flex-end;
    }

    .pipeform-layout-stepper .bottom .stepper-button.next {
        background-color: #FF5252;
        color: #FFFFFF;
    }
</style>