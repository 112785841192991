import axios from 'axios';
import rateLimit from 'axios-rate-limit';

const http = rateLimit(axios.create({
        baseURL: 'https://api.pipe.run/v1',
        headers: {
            'Content-Type': 'application/json'
        }
    }), {
        maxRequests: 2,
        perMilliseconds: 1000,
        maxRPS: 2
    });

export default http