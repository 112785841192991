<template>
    <div class="login">
        <div class="login-sidebar">
            <div class="login-sidebar-row">
                <img alt="PipeRun logo" src="../assets/logo_piperun_preta.png">
                <div class="login-form">
                    <form @submit.prevent="login">
                        <p class="help-block">Utilize os seus dados de acesso ao PipeRun para acessar o utilitário.</p>
                        <div class="form-group input-holder">
                            <label for="email">E-mail</label>
                            <input type="email" v-model="email" placeholder="Digite seu e-mail" required>
                        </div>
                        <div class="form-group input-holder">
                            <label for="password">Senha</label>
                            <input type="password" v-model="password" placeholder="Digite sua senha" required>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-success btn-block btn-lg">Entrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="login-canvas">
            <div class="login-slider">
                <div class="login-slider-wrapper">
                    <img src="../assets/whatsapp.jpg" alt="WhatsApp">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '../plugins/axios'
import SweetAlert from 'sweetalert2'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        login() {
            var data = {
                email: this.email,
                password: this.password
            }

            http.post('/auth', data)
                .then(response => {
                    this.$cookie.set('user_account_id', response.data.data.me.account_id, { expires: 3 })
                    this.$cookie.set('user_token', response.data.data.me.token, { expires: 3 })
                    this.$cookie.set('user_name', response.data.data.me.name, { expires: 3 })
                    this.$cookie.set('user_avatar', response.data.data.me.avatar, { expires: 3 })

                    return this.validateUser()
                })
                .catch(error => {
                    switch (error.response.status) {
                        case 401:
                            SweetAlert.fire({
                                title: 'Acesso Não Autorizado',
                                text: 'Verifique a senha inserida e tente novamente.',
                                icon: 'info',
                                confirmButtonColor: '#3085d6',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                },
                                buttonsStyling: false
                            })
                            break
                        case 503:
                            SweetAlert.fire({
                                title: 'Sistema Indisponível',
                                text: 'A API do PipeRun está fora do ar. Tente novamente mais tarde.',
                                icon: 'info',
                                confirmButtonColor: '#3085d6',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                },
                                buttonsStyling: false
                            })
                            break
                        default:
                            console.log(error)
                            break
                    }
                })
        },
        validateUser() {
            http.defaults.headers.token = this.$cookie.get('user_token')

            http.get('/accounts/' + this.$cookie.get('user_account_id'))
                .then(response => {
                    this.$cookie.set('user_account_name', response.data.data.name, { expires: 3 })
                    if (response.data.data.plan_id == 19) {
                        this.$cookie.set('enterprise', true, { expires: 3 })
                    } else {
                        this.$cookie.set('enterprise', false, { expires: 3 })
                    }
                })
                .catch(error => {
                    console.log(error);
                })

            http.get('/users/permissions')
                .then(response => {
                    response.data.data.forEach(element => {
                        // Gestor de contas
                        if (element.id === "G1") {
                            this.$cookie.set('is_manager', true, { expires: 3})
                        }
                    });

                    if (this.$cookie.get('is_manager')) {
                        this.$router.push({ name: 'Home' })
                        SweetAlert.fire('Acesso Autorizado', '<p>Bem vindo ao sistema!</p>', 'success');
                    } else {
                        SweetAlert.fire({
                            title: 'Acesso Não Autorizado',
                            text: 'Você não possui permissão de gestor de contas no sistema do PipeRun. Por favor, solicite acesso.',
                            icon: 'info',
                            confirmButtonColor: '#3085d6',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            },
                            buttonsStyling: false
                        })
                        this.$cookie.delete('user_name')
                        this.$cookie.delete('user_avatar')
                        this.$cookie.delete('user_token')
                        this.$cookie.delete('is_manager')
                        this.$cookie.delete('user_account_id')
                        this.$cookie.delete('user_account_name')
                        this.$cookie.delete('enterprise')
                    }
                }).catch(error => {
                    switch (error.response.status) {
                        case 401:
                            SweetAlert.fire({
                                title: 'Acesso Não Autorizado',
                                text: 'Verifique o token inserido e tente novamente.',
                                icon: 'info',
                                confirmButtonColor: '#3085d6',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                },
                                buttonsStyling: false
                            })
                            this.$cookie.delete('user_name')
                            this.$cookie.delete('user_avatar')
                            this.$cookie.delete('user_token')
                            this.$cookie.delete('is_manager')
                            this.$cookie.delete('user_account_id')
                            this.$cookie.delete('user_account_name')
                            this.$cookie.delete('enterprise')
                            break
                        case 503:
                            SweetAlert.fire({
                                title: 'Sistema Indisponível',
                                text: 'A API do PipeRun está fora do ar. Tente novamente mais tarde.',
                                icon: 'info',
                                confirmButtonColor: '#3085d6',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                },
                                buttonsStyling: false
                            })
                            this.$cookie.delete('user_name')
                            this.$cookie.delete('user_avatar')
                            this.$cookie.delete('user_token')
                            this.$cookie.delete('is_manager')
                            this.$cookie.delete('user_account_id')
                            this.$cookie.delete('user_account_name')
                            this.$cookie.delete('enterprise')
                            break
                        default:
                            console.log(error)
                            break
                    }
                })
        }
    }
}
</script>

<style>
    .login {
        height: 100%;
        display: flex;
        text-align: left;
    }

    .login-sidebar {
        padding: 32px;
        background: #fff;
        -webkit-box-shadow: 0 0 32px rgba(0,0,0,.35);
        box-shadow: 0 0 32px rgba(0,0,0,.35);
        z-index: 30;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-width: 350px;
    }

    .login-sidebar img {
        width: 100%;
        max-width: 250px;
        height: auto;
        margin-bottom: 16px;
    }

    .login-sidebar-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .login-form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 30px;
    }

    .login-canvas {
        height: 100vh;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        overflow: hidden;
        background: #ebf0f7;
    }

    .login-slider {
        height: 100%;
    }

    .login-slider-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 40px;
        position: relative;
        height: 100%;
    }

    .login-slider-wrapper img {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        -o-object-fit: cover;
        object-fit: cover;
        z-index: 0;
    }

    .help-block {
        margin-bottom: 25px;
        font-size: 12px;
    }

    .input-holder label {
        color: #848e99;
        display: block;
        margin-bottom: 4px;
    }

    .input-holder input {
        color: #616971;
        border: 1px solid #dadfe6;
        border-radius: 4px;
        padding: 12px;
        height: 34px;
        width: 100%;
        background: #ebf0f7;
        outline-color: #4cae4c;
        -webkit-box-shadow: 0 2px 2px #dadfe6 inset;
        box-shadow: inset 0 2px 2px #dadfe6;
    }
</style>