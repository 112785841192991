<template>
    <div id="app">
        <nav class="navbar navbar-default navbar-fixed-top navbar-piperun" v-if="logged">
            <div class="container-fluid">
                <div class="navbar-header">
                    <a class="navbar-brand" href="/">
                        <img src="https://crmpiperun.com/wp-content/uploads/2019/01/piperun-crm-vendas-favicon.png" alt="PipeRun Logo" width="20">
                    </a>
                </div>
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav navbar-right">
                        <li>
                            <router-link to="/">
                                <i class="glyphicon glyphicon-home"></i>
                                Início
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/about">
                                <i class="glyphicon glyphicon-bookmark"></i>
                                Sobre
                            </router-link>
                        </li>
                        <li class="dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                <img class="img-circle" :src="this.$cookie.get('user_avatar')" alt="Avatar" width="15">
                                    {{ this.$cookie.get('user_name') }} <span class="caret"></span>
                            </a>
                            <ul class="dropdown-menu">
                                <li><a href="#">Perfil</a></li>
                                <li role="separator" class="divider"></li>
                                <li>
                                    <a href="#" @click="logout">
                                        <i class="glyphicon glyphicon-log-out"></i>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div><!-- /.navbar-collapse -->
            </div><!-- /.container-fluid -->
        </nav>
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'App',
        methods: {
            logout() {
                if (this.loggedIn()) {
                    this.$cookie.delete('user_name')
                    this.$cookie.delete('user_avatar')
                    this.$cookie.delete('user_token')
                    this.$cookie.delete('is_manager')
                    this.$cookie.delete('user_account_id')
                    this.$cookie.delete('user_account_name')
                    this.$cookie.delete('enterprise')
                    this.$router.push({ name: 'Login' })
                }
            },
            loggedIn() {
                return (this.$cookie.get('user_token') !== null && this.$cookie.get('is_manager') !== null)
            }
        },
        computed: {
            logged() {
                return this.$route.path !== '/login'
            }
        }
    }
</script>

<style>
    #app {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        width: 100%;
        min-height: 100%;
    }

    .navbar-piperun {
        background-color:#131c21;
    }

    .navbar-piperun a {
        font-weight: bold;
        color: #FFFFFF !important;
        font-size: 10px;
    }

    .navbar-piperun .dropdown .dropdown-menu a {
        font-weight: bold;
        color: #2C3E50 !important;
        font-size: 10px;
        padding: 5px 15px;
    }

    .navbar-piperun .dropdown img {
        padding-bottom: 2px;
    }

    .container-piperun {
        margin-top: 75px;
    }
</style>
