<template>
    <div id="pipeform-layout-main">
        <div class="container-piperun">
            <div class="container">
                <div class="row pipeform-layout-init">
                    <div class="col-lg-8 col-lg-offset col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                        <h3>Gerador de Botão do WhatsApp</h3>
                        <p>
                            Crie um formulário para colocar como um widget no seu site, 
                            que tem a capacidade de enviar o lead gerado para dentro do PipeRun. 
                        </p>
                    </div>
                </div>
                <section class="section" style="padding-top: .5rem">
                    <div class="container">
                        <div class="columns">
                            <div class="column is-8 is-offset-2">
                                <Stepper :steps="demoSteps" @completed-step="completeStep" :top-buttons="true"
                                        @active-step="isStepActive" @stepper-finished="alert"></Stepper>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
    import MountForm from '@/components/MountForm'
    import CreateScript from '@/components/CreateScript'
    import ChoosePipeline from '@/components/ChoosePipeline'

    import Stepper from '@/components/Stepper'

    export default {
        name: "home",
        display: "Home",
        components: {
            Stepper
        },
        data() {
            return {
                demoSteps: [
                    {
                        icon: 'glyphicon glyphicon-chevron-right pipeform-icon',
                        name: 'first',
                        title: 'Campos do Formulário',
                        subtitle: 'Montagem do formulário de contato',
                        component: MountForm,
                        completed: false
                    },
                    {
                        icon: 'glyphicon glyphicon-chevron-right pipeform-icon',
                        name: 'second',
                        title: 'Funil e Etapa',
                        subtitle: 'Escolher funil e etapa para gerar a oportunidade',
                        component: ChoosePipeline,
                        completed: false
                    },
                    {
                        icon: 'glyphicon glyphicon-chevron-right pipeform-icon',
                        name: 'third',
                        title: 'Script',
                        subtitle: 'Gerar o script para incluir no seu site',
                        component: CreateScript,
                        completed: false
                    }
                ],
                activeStep: 0
            }
        },
        computed: {},
        methods: {
            completeStep(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        step.completed = true;
                    }
                })
            },
            isStepActive(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        if(step.completed === true) {
                            step.completed = false;
                        }
                    }
                })
            },
            alert(payload) {
                console.log(payload)
                alert('end')
            }
        }
    }
</script>

<style lang="css">
    /**
     * Estilos para a tela principal
     */
    #pipeform-layout-main {
        background: url('../assets/bkg-url-builder.png') no-repeat;
        background-position: center 30px;
        font-family: "Rubik", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    .pipeform-layout-init {
        padding-bottom: 25px;
    }

    .pipeform-layout-config, .pipeform-layout-parameters {
        margin-top: 25px;
    }

    .pipeform-layout-send {
        display: table;
        margin: 10px auto 0;
        height: 55px;
        line-height: 55px;
        font-weight: 700;
        color: #FFF;
        font-size: 18px;
        background: linear-gradient(to right,#FF5252, #ED5565);
        padding: 0 40px;
        text-transform: uppercase;
        border-radius: 6px;
    }

    .pipeform-layout-send:hover {
        background: linear-gradient(to left,#FF5252, #ED5565);
        color: #FFF;
    }

    /**
     * Estilos para o layout do formulário
     */
    .pipeform-layout-form {
        border: 1px solid #CCCCCC;
        min-height: 200px;
        border-radius: 5px;
        background-color: #EEEEEE;
    }

    .pipeform-layout-form-header {
        background-color: #075E54;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 55px;
        padding: 10px 25px;
    }

    .pipeform-layout-form-header p {
        color: #FFFFFF;
        font-size: 20px;
    }
    
    .pipeform-layout-form-elements {
        border: 1px dotted #CCCCCC;
        min-height: 250px;
        border-radius: 3px;
        background-color: #ECE5DD;
        padding: 10px;
    }

    .pipeform-layout-form-element {
        background-color: #FDFDFD;
        border-color: #CCCCCC;
        color: #2F2F2F;
        border-radius: 10px;
        width: 100%;
        height: 80px;
        padding: 1em;
        margin: 10px 0;
        opacity: 0.8;
    }

    .pipeform-layout-form-button {
        padding: 15px 0;
    }

    .pipeform-layout-form-accept {
        padding: 5px 0;
    }
    
    .pipeform-layout-form-footer {
        text-align: right;
        padding-right: .2em;
    }

    .pipeform-layout-form-footer p {
        font-size: 9px;
    }

    .pipeform-layout-form-footer a:hover {
        color: #ED5565;
        text-decoration: none;
    }

    .pipeform-layout-form-logo {
        width: 85px;
        height: 85px;
        float: right;
        position: relative;
    }

    /**
     * Estilos para os elementos do formulário que o cliente pode escolher
     */
    .pipeform-list-group {
        border: 1px dotted #CCCCCC;
        min-height: 100px;
        border-radius: 5px;
        background-color: #EFEFEF;
    }

    .pipeform-list-group-item {
        background-color: #ED5565;
        border-color: #FF275B;
        color: #FFFFFF;
        border-radius: 10px;
        width: 95%;
        height: 80px;
        padding: 1em;
        margin: 5px;
    }

    input:focus {
        outline: none;
    }

    template {
        background-color: #cccccc;
    }
</style>