<template>
    <div style="padding: 6rem 3rem; text-align: left;">
    <div class="row">
        <div class="col-sm-12">
            <div class="well">
                <h4>Script Gerado</h4>
                <p>
                    Agora é só copiar e colar no seu site para utilizar.
                </p>
                <div class="pipeform-layout-script">
                    <p>
                        <code ref="pipeform_whatsapp_code">
                            {{ script_text }}
                        </code>
                    </p>
                    <button class="btn btn-lg btn-block pipeform-layout-send" @click="copyScript">
                        Copiar Script
                    </button>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'CreateScript',
    data() {
        return {
            json: '{}',
            script_text: null
        }
    },
    methods: {
        copyScript() {
            this.selectText(this.$refs.pipeform_whatsapp_code);
            document.execCommand('copy');
        },
        selectText(element) {
            var range;
            if (typeof document.selection === "function") {
                // Internet Explorer
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (typeof window.getSelection === "function") {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
        },
        formatDate(date) {
            return date.getFullYear()
                + '-' + 
                (date.getMonth() + 1)
                + '-' + 
                (date.getDate() < 10 ? '0' : '') + date.getDate() + ' ' +
                date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
        }
    },
    mounted() {
        var rules = {
            'update': localStorage.getItem('update'),
            'filter_status_update': null,
            'filter_situation_update': null,
            'equal_pipeline': true,
            'status': localStorage.getItem('state'),
            'situation': localStorage.getItem('situation'),
            'validate_cpf': localStorage.getItem('validate_cpf'),
            'validate_cnpj': localStorage.getItem('validate_cnpj')
        }

        var filter_status_update = [];
        var filter_situation_update = [];

        JSON.parse(localStorage.getItem('status_update')).forEach(element => {
            filter_status_update.push(element.name)
        })
        rules.filter_status_update = filter_status_update.join(",")

        JSON.parse(localStorage.getItem('situation_update')).forEach(element => {
            filter_situation_update.push(element.name)
        })
        rules.filter_situation_update = filter_situation_update.join(",");

        var data = {
            'form': {
                'header': {
                    'text': localStorage.getItem('header_text'),
                    'background_color': '#075E54',
                    'color': '#FFFFFF'
                },
                'content': {
                    'fields': JSON.parse(localStorage.getItem('fields')),
                    'background_color': '#ECE5DD',
                    'color': '#2F2F2F'
                },
                'button': {
                    'text': localStorage.getItem('button_text'),
                    'background_color': localStorage.getItem('button_color'),
                    'color': '#FFFFFF'
                },
                'accept': {
                    'text': localStorage.getItem('accept_text'),
                    'color': '#2F2F2F'
                },
                'logo': {
                    'color': localStorage.getItem('whatsapp_color')
                }
            },
            'enterprise': this.$cookie.get('enterprise'),
            'hash': localStorage.getItem('stage_hash'),
            'identifier': '',
            'position': localStorage.getItem('position'),
            'origin': localStorage.getItem('origin'),
            'tags': [],
            'title': localStorage.getItem('deal_title'),
            'contact_phone': localStorage.getItem('contact_phone'),
            'success_page_url': localStorage.getItem('success_page_url'),
            'rules': rules
        }

        var tags = JSON.parse(localStorage.getItem('tags'))
        var identifier = JSON.parse(localStorage.getItem('identifier'))

        tags.forEach(element => {
            data.tags.push(element.name)
        })

        data.identifier = identifier.name

        this.json = JSON.stringify(data)

        this.script_text = '<script type="text/javascript">' + 
            'window.piperun_data = ' + this.json + ';<' + '/' + 'script>' + 
            '<script src="' + window.location + 'script.min.js" type="text/javascript"><' + '/' + 'script>'
    }
}
</script>

<style>
    code {
        word-break: break-all;
        padding: 0 !important;
    }
</style>